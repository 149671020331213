<template>
    <ListBasicComponent end-point="seasons" title="シーズン" :field="fields"></ListBasicComponent>
</template>
<script>
    import ListBasicComponent from "@/components/views/ListBasicComponent";

    export default {
        name: 'Seasons',
        components: { ListBasicComponent },
        data() {
            return {
                fields: [
                  // { key: 'select', label: '' },
                  { key: 'id', label: 'ID'},
                  { key: 'name', label: '名前' },
                  { key: 'action', label: '' },
                ]
            }
        }
    }
</script>
